import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from "react-bootstrap";
import { Video, thinkTime, sky, answerDuration } from "../../helper/Icons/Icon";
import interview from "../../assets/images/interview.jpg";
import { CustomButton } from "../form";
import Swal from 'sweetalert2';
import Spinner from '../common/Spinner';

import { useParams } from 'react-router-dom';
import { addquestionAnswer, updateInterveiewTimeine, addquestionAnswerBlob } from "../../store/actions/InterviewproceedAction";
const config = require('../../config/config.json');

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const RecordVideo = ({ question, answer, activeStep, reLoad }) => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const videotag = useRef(null);
    const mediaRecorder = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [thinkTimeShow, SetThinkTimeShow] = useState(true);
    const [vurl, setVurl] = useState(answer?.interview_answer ?? null);
    const [mLoading, setMLoading] = useState(false);

    const [retakeVideo, setRetakeVideo] = useState(false);
    const [retakeCount, setRetakeCount] = useState(answer?.answer_retake ?? 0);
    const [videoChunks, setVideoChunks] = useState([]);
    const [startStreem, setStartStreem] = useState(null);
    const [thinkTime, setThinkTime] = useState(0);
    const [responseTimeCount, setResponseTimeCount] = useState(0);

    const [responseTime, setResponseTime] = useState(answer?.response_time_insec ?? 0);
    const { loading, error } = useSelector((state) => state.Interviewproceed);
    const totalResponseTime = 5 * 60;
    const th = useRef();
    const rs = useRef();
    const responseTimeCountInterval = useRef(null)

    useEffect(() => {
        if (!isRecording && !answer?.interview_answer && thinkTimeShow) {
            th.current = setInterval(() => setThinkTime(prevSeconds => prevSeconds + 1), 1000)
        }

        if (isRecording) {
            rs.current = setInterval(() => setResponseTime(prevSeconds => prevSeconds + 1), 1000)
            responseTimeCountInterval.current = setInterval(() => setResponseTimeCount(prevSeconds => prevSeconds + 1), 1000)

        }

    }, [isRecording, answer, vurl, thinkTimeShow]);
    useEffect(() => {
        if (responseTimeCount >= totalResponseTime) {
            stopRecording(true)
        }
    }, [responseTime])
    const startRecording = async (retacke) => {
        setMLoading(true)
        if (!answer?.interview_answer) {
            clearInterval(th.current);
        }

        try {

            const stream = await navigator.mediaDevices.getUserMedia({
                video: true, audio: true
            });
            videotag.current.srcObject = stream;
            mediaRecorder.current = new MediaRecorder(stream, { mimeType: 'video/webm' });
            mediaRecorder.current.start();

            setMLoading(false)
            setStartStreem(stream)
            setIsRecording(true);
            SetThinkTimeShow(false)
            if (retacke) {
                setRetakeCount(retakeCount + 1)
            }

            const chunks = [];
            mediaRecorder.current.ondataavailable = (event) => {
                chunks.push(event.data);
            };

            setVideoChunks(chunks);

        } catch (error) {
            setMLoading(false)
            SetThinkTimeShow(true)
            let errorMessage = "Error: Could not start video source";
            if (error.name === "NotReadableError") {
                errorMessage += ". This could be due to permission issues, browser support, or device availability.";
                setMLoading(false)
            } else if (error.name === "NotAllowedError") {
                setMLoading(false)
                SetThinkTimeShow(true)
                errorMessage += ". Please grant permission to access the camera and microphone.";
            }

            Swal.fire({
                icon: "error",
                title: "Error!",
                text: errorMessage,
                showConfirmButton: false,
                timer: 2000
            });

        }
    };

    const stopRecording = (retacke) => {
        clearInterval(rs.current);
        clearInterval(responseTimeCountInterval.current);
        if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
            mediaRecorder.current.stop();
        }

        mediaRecorder.current.onstop = () => {
            const blob = new Blob(videoChunks, { type: 'video/webm' });
            var reader = new FileReader();
            reader.readAsDataURL(blob);

            reader.onloadend = function () {
                var base64data = reader.result;

                setRecordedBlob(blob);

                const videoUrl = window.URL.createObjectURL(blob);
                setVurl(videoUrl)

                setMLoading(true)
                const formData = new FormData();

                formData.append("interview_template_question_id", question.interview_template_question_id);
                formData.append("retakes", retakeCount);
                formData.append("response_time", Math.ceil(responseTime / 60));
                formData.append("response_time_insec", responseTime);
                formData.append("think_time", Math.ceil(thinkTime / 60));
                formData.append("interview_answer", videoUrl);
                formData.append("interview_answer_encoded", blob);
                formData.append("question_text", question.question_text);
                formData.append("answer_media", question.question_type_name);

                // const payload = {
                //     interview_template_question_id: question.interview_template_question_id,
                //     retakes: retakeCount,
                //     response_time: Math.ceil(responseTime / 60),
                //     response_time_insec: responseTime,
                //     think_time: Math.ceil(thinkTime / 60),
                //     interview_answer: videoUrl,
                //     interview_answer_encoded: base64data,
                //     question_text: question.question_text,
                //     answer_media: question.question_type_name,

                // };
                // dispatch(addquestionAnswer({ token, payload }))
                //     .then((res) => {
                //         reLoad(true)
                //     })
                //     .catch(() => {
                //         sweetAlert(
                //             "error",
                //             "Error!",
                //             "Something went wrong!"
                //         );
                //     });



                dispatch(addquestionAnswerBlob({ token, formData }))
                    .then((res) => {

                        reLoad(true)
                        setMLoading(false)
                    })
                    .catch(() => {
                        sweetAlert(
                            "error",
                            "Error!",
                            "Something went wrong!"
                        );
                    });

                let qNumber = activeStep + 1;
                const log = {
                    key: 'video',
                    log: `Submitted Response for ${question.question_type_name} Question ${qNumber}`,
                    date_time: new Date()

                };
                dispatch(updateInterveiewTimeine({ token, log }));

                videotag.current.srcObject = null;
                startStreem.getTracks().forEach(track => track.stop());

                setIsRecording(false);
                setRetakeVideo(false);
                setVideoChunks([]);
                setStartStreem(null)
                reLoad(false)
            }
        };
    };



    return (

        <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-ligte-gray  ">
            {mLoading && <Spinner />}
            <Col md={12} className="RecordVideo-wrap sm-pad-0">
                <h4 className="sub-heading">
                    <span className="mr-5">{Video}Record Video:</span>

                </h4>

                <div className="video-section  sm-t-10  ">
                    {(!answer?.interview_answer || retakeVideo) &&
                        <>
                            <div class="status">Ready</div>
                            <video height="100%" autoPlay muted style={{ background: "black", borderRadius: "10px" }} ref={videotag} />
                            {/* <div class="camera-icon">
    <i class="fas fa-video"></i>
</div> */}

                        </>
                        //<video height="100%" width="70%" autoPlay poster={interview} ref={videotag} />





                    }
                    {(!retakeVideo && answer?.interview_answer) && (
                        <video height="100%" controls>
                            <source src={`${vurl}`} type="video/webm" />
                            Your browser does not support the video tag.
                        </video>

                    )}

                    <div className="">

                        <div className="">
                            {(!answer?.interview_answer || retakeVideo) && (
                                <>
                                    <div className="retake-btn-wrap laptop-screen-show">


                                        {retakeVideo ?
                                            <>
                                                <CustomButton text="Record Now" disabled={isRecording} className=" custom-btn-primary mt-25 mr-10 " onClick={() => startRecording(true)} />
                                                <CustomButton text="Save & Continue" disabled={!isRecording} className="custom-btn-outline mr-10 mt-25 mr-10" onClick={() => stopRecording(true)} />
                                                <CustomButton disabled={isRecording} className="custom-btn-outline mr-10 mt-25" onClick={() => setRetakeVideo(false)} text="Cancel Retake" />
                                            </>
                                            :
                                            <>
                                                <CustomButton text="Record Now" disabled={isRecording} className=" custom-btn-primary mt-25 mr-10" onClick={() => startRecording(false)} />
                                                <CustomButton text="Save & Continue" disabled={!isRecording} className="custom-btn-outline mr-10  mt-25" onClick={() => stopRecording(false)} />
                                            </>
                                        }
                                    </div>
                                    <div className="retake-btn-wrap mobile-view-recoreded-btn-show ">


                                        {retakeVideo ?
                                            <>
                                                <CustomButton text="Record" disabled={isRecording} className=" custom-btn-primary mt-25 mr-10 " onClick={() => startRecording(true)} />
                                                <CustomButton text="Save" disabled={!isRecording} className="custom-btn-outline mr-10 mt-25 mr-10" onClick={() => stopRecording(true)} />
                                                <CustomButton disabled={isRecording} className="custom-btn-outline mr-10 mt-25" onClick={() => setRetakeVideo(false)} text="Cancel" />
                                            </>
                                            :
                                            <>
                                                <CustomButton text="Record Now" disabled={isRecording} className=" custom-btn-primary mt-25 mr-10" onClick={() => startRecording(false)} />
                                                <CustomButton text="Save & Continue" disabled={!isRecording} className="custom-btn-outline mr-10  mt-25" onClick={() => stopRecording(false)} />
                                            </>
                                        }
                                    </div>
                                    {/* <span className="mt-1 video-response-time" style={{ display: isRecording ? 'block' : 'none' }}>Responding time: {responseTime}</span> */}
                                    <div className="mt-1 video-response-time" style={{ display: isRecording ? 'block' : 'none' }}> <div className="d-flex align-items-center"> <span className="mr-10" >{answerDuration}</span><span className="play-time">Responding time:{responseTime}</span></div></div>

                                    {(!isRecording && !answer?.interview_answer) &&

                                        (thinkTimeShow && <div className="mt-1 video-response-time"><span className="mr-10" >{sky}</span><span className="play-time">Thinking time: {thinkTime}</span></div>)

                                    }
                                </>
                            )}
                            {(!retakeVideo && answer?.interview_answer) && (
                                <>
                                    {/* <CustomButton className="custom-btn-outline mr-10 mt-25" onClick={() => handleDownload(`${answer?.interview_answer}`)} text="Download Video" /> */}
                                    {question.retakes !== answer?.answer_retake &&
                                        <CustomButton className="custom-btn-primary video-retak-btn" onClick={() => setRetakeVideo(true)} text="Retake" />
                                    }

                                </>
                            )}
                        </div>

                    </div>
                </div>

            </Col>
        </Row>


    );
};

export default RecordVideo;
