import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import { Label, InputField, CustomButton, FeedBack } from "../form/index";
import { useNavigate, Link } from 'react-router-dom';
import { fieldConfigRequestdemo } from './FieldConfig';
import { requestomDemo } from '../../store/actions/InterviewproceedAction';
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Spinner from "../common/Spinner";
import 'react-phone-number-input/style.css';
import { vidhireProLogo } from '../../helper/Icons/Icon';

import Imge from "../../assets/images/login-infographics.svg"
const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 9000
    });
}
const ERROR_MESSAGES = {
    name: "Full name is required",
    email: "Email address is required",
    company_name: "Company name is required",
    phone: "Phone number is required"

}
const Requestdemo = () => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { loading } = useSelector(((state) => state.Interviewproceed))
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        company_name: "",
        phone: "",
    });
    const [errors, setErrors] = useState({});
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // const businessEmailPatter = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com$|yahoo\.com$|hotmail\.com$|aol\.com$|icloud\.com$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateFormData(name, value);
    };

    const updateFormData = (name, value) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = '';
        if (!value?.trim()) {
            if (name === 'email') {
                error = 'Email address is required';
            }
            if (name === 'phone') {
                error = 'Phone number is required';
            }
            if (name === 'company_name') {
                error = 'Company name is required';
            }

            if (name === 'name') {
                error = 'Full name is required';
            }

        } else {
            if (name === 'email' && !emailPattern.test(value)) {
                error = '';
            } else if (name === 'phone' && (!isValidPhoneNumber(value) || value.length < 12)) {

                error = 'Enter a valid phone number';
            }
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    const validateForm = () => {
        const validationErrors = {};
        Object.keys(formData).forEach((field) => {
            const fieldValue = formData[field] || "";
            if (!fieldValue.trim()) {
                validationErrors[field] = ERROR_MESSAGES[field];
            }
        });
        if (formData.email && !emailPattern.test(formData.email)) {
            validationErrors.email = 'Enter a valid email address';
        }
        if (formData.phone && (!isValidPhoneNumber(formData.phone) || formData.phone.length < 12)) {
            validationErrors.phone = 'Enter a valid phone number';
        }
        return validationErrors;
    };

    const FormRequestdemo = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        // Check for validation errors first
        if (Object.keys(validationErrors).length === 0) {
            dispatch(requestomDemo(formData))
                .then((res) => {

                    if (res.payload && !res.payload.error) {
                        const message = res.payload.message;
                        sweetAlert("success", "Success!", message);
                        setMessage(message);
                    } else {

                        sweetAlert("error", "Error!", res?.payload?.error);
                    }
                })
                .catch((error) => {

                    console.error("API Error:", error);


                    const errorMessage = error.payload?.error || "Something went wrong!";
                    sweetAlert("error", "Error!", errorMessage);
                });
        } else {
            setErrors(validationErrors);
        }
    };


    return (

        <Container fluid className='pad-0'>
            {loading && <Spinner />}
            <Row className='row-pad-0 wrap-requesd-demo'>
                <Col md={6} className='pad-0'>

                    <div className='logo'><img src={vidhireProLogo} alt='Logo' /></div>
                    <div
                        className='bg-image'
                        style={{
                            backgroundImage: `url(${Imge})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "100vh",
                        }}>

                    </div>
                    {/* <div className='info-graphics'></div> */}
                </Col>

                <Col md={6} className='justify-content-center requestdemo-left d-flex'>
                    <Form onSubmit={FormRequestdemo}>
                        <Row className='row-pad-0'>
                            <Col md={12}>
                                {/* <br /> */}
                                <p className='accountSetting-p'>
                                    Recruit Smarter, Not Harder: Start Interviewing in  Style!
                                </p>
                                <p className='accountSetting-p mb-10'></p>
                                <span className='accountSetting-span'>
                                    Streamline hiring with one-way and live video interviews—evaluate candidates efficiently, reduce bias, and accelerate decisions
                                </span>
                            </Col>
                            <p className=' mb-20'></p>
                            {message ? (<>
                                <div
                                    className="message-request-demo"

                                >
                                    <div >Please check your inbox and verify your email address</div>
                                </div>




                            </>

                            ) : (<>
                                {fieldConfigRequestdemo.map((field, index) => (
                                    <Col md={6} xs={12} className='mb-10 mt-10' key={index}>
                                        <Label
                                            htmlFor={field.id}
                                            className={field.className}
                                            text={field.text}
                                            required="required"
                                        />
                                        {field.id === "phone" ? (
                                            <PhoneInput
                                                defaultCountry="US"
                                                placeholder="Enter phone number"
                                                value={formData.phone}
                                                onChange={(value) => updateFormData("phone", value)}
                                                error={errors.phone || undefined}
                                            />
                                        ) : (
                                            <InputField
                                                type={field.type}
                                                id={field.id}
                                                name={field.id}
                                                placeholder={field.placeholder}
                                                value={formData[field.id]}
                                                onChange={handleChange}
                                            />
                                        )}
                                        {errors[field.id] && (
                                            <FeedBack className="error" error={errors[field.id]} />
                                        )}
                                    </Col>
                                ))}

                                {/* <Col md={6} xs={12} className='mb-10 mt-10'>
                                    <TimeZone />
                                </Col> */}
                                <Col className="mb-10 mt-20" md={12} >
                                    <Row className="wrap-signup">



                                        <CustomButton text="Sign Up" className="custom-btn-primary" type="submit" />

                                    </Row>
                                    <Col className='text-end col-md-12  have-account'>
                                        <span className="">Already have an account?<Link to="/login" className='custom-link'> Login</Link></span>
                                    </Col>
                                </Col>
                            </>)}
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container >
    );
};

export default Requestdemo;
