import React, { useState } from 'react';
import { CustomButton } from "../form";
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { stripePayment } from "../../store/actions/accountBillingAction";
import Spinner from '../common/Spinner';
const UpgradePlan = ({ clr, btntext, data, staticCard }) => {
    const dispatch = useDispatch();
    const [payment, setPayment] = useState(false);

    const PlanOverviewbtn = async (label) => {


        setPayment(true)
        try {
            const stripe = await loadStripe("pk_test_aJZLyiunJxvJkisL6rnAm96p");

            const id = label.id;

            dispatch(stripePayment(id)).then((res) => {
                setPayment(false)

                if (res.payload.sessionId) {

                    const result = stripe.redirectToCheckout({
                        sessionId: res.payload.sessionId
                    });

                    if (result.error) {
                        console.error('Error redirecting to Stripe checkout:', result.error);
                    }

                }

            });


        } catch (error) {
            console.error("Error loading Stripe:", error);
        }
    };

    return (
        <>
            {payment && <Spinner />}
            <div className={`plan-rate-wrap ${clr}`}>
                <div className="plan-rate">
                    <div className="plan-title">
                        <span className="lite">{data.package_name}</span>

                        <div className="rate d-flex">
                            {staticCard ? null : <span className="curreny">$</span>}
                            <div className="price-wrap" style={staticCard ? {} : { maxHeight: "65px" }}>
                                {data.price && <h4 className="price">{data.price}</h4>}
                                {data.contract && <h4 class="lite">{data.contract}</h4>}
                                {staticCard ? null : (data?.no_of_days === 30 ? <span className="month"> /mo</span> : <span className="month"> /ann</span>)}
                            </div>
                        </div>

                    </div>
                    <div className="content-rate-plan">
                        <div className='wrap-tax'>


                            {!staticCard && <p className="p-common">+ applicable tax</p>}
                            {staticCard && <p className="contract">Billed Annually</p>}
                            {!staticCard && (
                                data?.no_of_days === 30
                                    ? <p className="contract">Billed Monthly (no contract)</p>
                                    : <p className="contract">Billed Annually</p>
                            )}

                        </div>
                        <div className="w-content">
                            <p className="p-common">Unlimited interviews</p>
                            {data.allowed_jobs && <p className="p-common">{data.allowed_jobs} jobs</p>}
                            {data.allowed_users && <p className="p-common">{data.allowed_users} users</p>}
                            {data.allowed_emails && <p className="p-common">{data.allowed_emails} email credits</p>}
                            {data.allowed_sms && <p className="p-common">{data.allowed_sms} SMS credits</p>}
                        </div>
                    </div>
                    <div className="wrap-btn">
                        {staticCard ? <CustomButton text={btntext} onClick={() => window.open('https://vidhirepro.com/contact', '_blank')} className="rate-btn" /> : <CustomButton text={btntext} onClick={() => PlanOverviewbtn(data)} className="rate-btn" />}
                    </div>
                </div>
            </div >
        </>
    );
};

export default UpgradePlan;
